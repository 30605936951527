<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>
        <e-col style="min-width: 45%;">
          <date-input label="Data de Entrada (De)" v-model="filters.dataEntrada1"/>
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data de Entrada (Até)" v-model="filters.dataEntrada2"/>
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data de Saída (De)" v-model="filters.dataSaida1"/>
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data de Saída (Até)" v-model="filters.dataSaida2"/>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Reboquista"
          >
            <reboquista-input v-model="filters.reboquista"/>
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 48%">
          <erp-s-field
              view="tl"
              label="Pátio"
          >
            <patio-input v-model="filters.patio"/>
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 48%">
          <status-select multiple v-model="filters.status" />
        </e-col>

        <e-col style="min-width: 98%">
          <comitente-select multiple :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="filters.comitentes" />
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Separar por"
          >
            <div class="flex">
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.separarPor" val="ano" class="m-r-xs"/>
                Ano
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.separarPor" val="mes" class="m-r-xs"/>
                Mês
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.separarPor" val="dia" class="m-r-xs"/>
                Dia
              </label>
            </div>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import ReboquistaInput from "@/components/reboquista/helpers/input/ReboquistaInput"
import {date, URadio} from 'uloc-vue'
import mixinPrint from "@/components/relatorios/components/mixinPrint";
import {remocaoEntradaSaida} from "@/domain/relatorios/services";
import {datePtToEn} from "@/utils/date";
import PatioInput from "@/components/patio/helpers/input/PatioInput";
import StatusSelect from "@/components/bem/components/include/StatusSelect";
import ComitenteSelect from "@/components/comitente/helpers/input/ComitenteSelect";

let filters = {
  dataEntrada1: null,
  dataEntrada2: null,
  dataSaida1: null,
  dataSaida2: null,
  reboquista: null,
  patio: null,
  vistoriador: null,
  comitentes: [],
  status: [],
  separarPor: 'mes'
}

export default {
  name: 'RelatorioEstoqueWindow',
  mixins: [mixinPrint],
  data() {
    return {
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    printViewCustom: function (data, type = 'gerencial', title = 'Relatório Gerencial') {
      let window = 'printView-' + data.type  + '-' + data.id
      !this.$uloc.printView.get(window) && this.$uloc.printView.new({
        wid: window,
        title: title,
        windowClass: 'erp-print',
        contentClass: '',
        props: {
          data: data
        }
      }, () => import('@/components/relatorios/components/customPrint/RemocaoEntradaSaida'))
          .then((wid) => {
          }) // return wid
    },
    gerar() {
      let data1, data2, dataS1, dataS2
      let filters = JSON.parse(JSON.stringify(this.filters))
      this.loading = true
      if (filters.dataEntrada1) {
        data1 = datePtToEn(filters.dataEntrada1)
        data2 = datePtToEn(filters.dataEntrada2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.dataEntrada1 = data1
        filters.dataEntrada2 = data2
      }

      if (filters.dataSaida1) {
        dataS1 = datePtToEn(filters.dataSaida1)
        dataS2 = datePtToEn(filters.dataSaida2)
        if (!date.isValid(dataS1) || !date.isValid(dataS2)) {
          alert('Digite a data inicial e data final para a data de saída corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.dataSaida1 = dataS1
        filters.dataSaida2 = dataS2
      }

      if (Array.isArray(this.filters.comitentes) && this.filters.comitentes.length > 0) {
        filters.comitentes = this.filters.comitentes.join(',')
      }

      if (Array.isArray(this.filters.status) && this.filters.status.length > 0) {
        filters.status = this.filters.status.join(',')
      }

      remocaoEntradaSaida(filters)
          .then(response => {
            this.loading = false
            this.printViewCustom(response.data, 'remocao')
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  },
  components: {
    ComitenteSelect,
    StatusSelect,
    PatioInput,
    ReboquistaInput,
    DateInput,
    ECol,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    // ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    URadio
    // ErpSelect
  }
}
</script>
